import * as React from "react"
import { Container, Row } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { fadeIn } from "react-animations"
import styled, { keyframes } from "styled-components"
import Introduction from "../components/pageIntroduction"
import TitleTextCol from "../components/titleTextCol"
import BlockIntroduction from "../components/blockIntroduction"
import TextImage from "../components/textImage"
import HorizontalSlider from "../components/horizontalSlider"
import Cta from "../components/cta"
import Project from "../components/project"
import Image from "../components/elements/image"
import HooxBrandmark from "../images/hoox-brandmark.inline.svg"
import SitenuBrandmark from "../images/sitenu-brandmark.inline.svg"
import SendtodeliverBrandmark from "../images/sendtodeliver-brandmark.inline.svg"
import WildeBrandmark from "../images/wilde-brandmark.inline.svg"
import MetaImage from "../images/featured/loyall-featured-image-website-light.jpg"
import Favicon from "../images/favicon.png"

const IndexPage = props => {
  const fadeInAnimation = keyframes`${fadeIn}`

  const FadeInDiv = styled.div`
    animation: 5s ${fadeInAnimation};
  `
  return (
    <Layout>
      <div className="page">
        <Seo
          title="Loyall – Wij zijn een digital lifecycle agency"
          image={{ src: MetaImage, height: 628, width: 1200 }}
          description="We hebben de complete digitale mix in huis om jouw bedrijf duurzaam te laten groeien. Van marketing tot development, van design tot integraties. Met de digitale lifecycle investeren we in krachtige relaties met je doelgroep."
          pathname={props.location.pathname}
          favicon={Favicon}
        />
        <Introduction
          title="We are loyalty makers."
          subtitle="Wij zijn een digital lifecycle agency: we hebben de complete digitale mix om je bedrijf op een duurzame manier te laten groeien en je klanten Loyall te maken."
        />
        <FadeInDiv>
          <Container className="image-header">
            <Image fadeIn={true} imgsrc="loyall-header.jpg" loading="eager" />
          </Container>
        </FadeInDiv>
        <TitleTextCol
          title="Het digitale landschap verandert in een rap tempo."
          paragh1="En daar willen alle organisaties op inspelen. Maar wat als je niet alle onderdelen voor een succesvol resultaat weet samen te voegen? Waar design nodig is, komt vaak zowel marketing als development om de hoek kijken. Als we online marketing inzetten kan dit niet zonder strak design. Waar uitdagingen overwonnen moeten worden, is er niet één oplossing."
          paragh2="Elk ingrediënt van de digital lifecycle is even belangrijk; ze versterken elkaar. En wij hebben alle ingrediënten in huis."
        />
        <TextImage
          title="Waar het allemaal om draait"
          intro="Met de digital lifecyle investeer je in krachtige en duurzame relaties met je doelgroep."
          paragh="De lifecycle draait erom aandacht te trekken van je doelgroep, om vervolgens banden te leggen en relaties aan te gaan. We willen dat websitebezoekers uitgroeien tot waardevolle leads en klanten, om uiteindelijk je organisatie te promoten binnen hun netwerk. De lifecycle werkt alleen als we design, marketing, development en integraties combineren. Want alleen dan kun je de beste digitale ervaring bieden. En alleen dan blijven mensen betrokken."
          link=""
          linkText=""
          image="loyall-lifecycle-full-pizza-1.png"
          col={true}
          classes="pizza-block"
        />
        <Cta
          verticalStyling={false}
          title="De labels."
          intro="Ons team bestaat uit digitale lifecycle experts: wij weten wat nodig is om jouw doelgroep in loyale klanten te veranderen."
          link="/onze-aanpak"
          linkText="Onze aanpak"
        />
        <HorizontalSlider
          content={[
            {
              image: "loyall-labels-design-image.jpg",
              logo: <WildeBrandmark />,
              title: "Design",
              text: "Onze designers ontdekken wat jouw bedrijf mooier maakt. Ze brengen je organisatie tot leven en helpen je een betekenisvolle visuele identiteit te creëren. Of het nu een vernieuwde branding, web design of print gaat; het fundament is de connectie met je doelgroep. Met design ontwerpen we om die doelgroep te inspireren.",
            },
            {
              image: "loyall-labels-marketing-image.jpg",
              logo: <SendtodeliverBrandmark />,
              title: "Marketing",
              text: "Hoe breng je jouw verhaal het beste over op je doelgroep? Het is de kracht van onze marketeers om ervoor te zorgen dat je meerwaarde kunt bieden aan je doelgroep. Hoe benaderen we ze? Waar gaan we ze bereiken? Wat vertellen we? En hoe stimuleren we hen over te gaan tot actie? Met marketing lokken we reactie uit.",
            },
            {
              image: "loyall-labels-development-image.jpg",
              logo: <SitenuBrandmark />,
              title: "Development",
              text: "Hoe heeft jouw organisatie interactie met de buitenwereld? Onze developers zorgen voor websites en applicaties die meer dan alleen functioneel zijn. We gaan voor toegankelijkheid, innovatie en een holistische ervaring. We vertalen het design en versterken marketing. Met development zetten we de versnelling in.",
            },
            {
              image: "loyall-labels-integrations-image.jpg",
              logo: <HooxBrandmark />,
              title: "Integrations",
              text: "Hoe behoud je de connectie met je huidige en toekomstige klanten? Wat doe je met je verzamelde data? Waar verzamel je eigenlijk data? Met integraties zorgen we ervoor dat je alles vanaf één locatie kunt doen. Automatiseer je taken, maak dashboards met real-time data en biedt de beste service. Met integraties leggen we duurzame verbindingen.",
            },
          ]}
        />
        <BlockIntroduction
          title="Ons werk"
          intro={
            <h4>
              Nee kennen we niet, alles kan. <br></br> En dit hebben we al
              gedaan.
            </h4>
          }
          customIntro={false}
        />
        <Container className="projects">
          <Row>
            <Project
              type="small"
              image="cegeka-2.jpg"
              comingSoon={true}
              title="Cegeka"
              labels={["design", "marketing"]}
              delay={700}
              animationDuration={500}
            />
            <Project
              type="large"
              image="startdock-1.jpg"
              comingSoon={true}
              title="Startdock"
              labels={["development", "integrations"]}
              offset={1}
              delay={400}
              animationDuration={300}
            />
          </Row>
        </Container>
        <Cta
          verticalStyling={false}
          title=""
          intro="Doelstellingen vertalen naar design, development en marketing."
          link="/discovery"
          linkText="Ontdek de mogelijkheden"
        />
      </div>
    </Layout>
  )
}

export default IndexPage
